S
<template>
  <AppTable ref="table" :fields="fields" :items="items" primary-key="id" v-bind="$attrs" v-on="$listeners">
    <template #cell(interact)="{ item }">
      <label>
        <input type="checkbox" :disabled="item.status" :checked="isSelected(item)" @change="toggleSelected(item)" />
      </label>
    </template>

    <template #cell(name)="{ item }">
      <div>{{ item.name }}</div>
      <router-link class="btn btn-outline-primary action action--stats" :to="`/scrim/${item.id}/edit`">
        <b-icon-pencil></b-icon-pencil> Edit
      </router-link>
    </template>

    <template #cell(created)="{ item }">
      <div class="column">
        <VodStatusIndicator :status="item.replay_cropper_status" />

        <time :datetime="item.created | isoString" class="column">
          <span>{{ item.created | localDate }}</span>
          <span>{{ item.created | localTime }}</span>
        </time>
      </div>
    </template>

    <template #cell(map)="{ item }">
      <MapCell :id="item.map" v-if="item.map" variant="col" size="lg" />
      <VodStatusIndicator v-else :status="item.replay_cropper_status" />
    </template>

    <template #cell(team1)="{ item }">
      <div class="column">
        <TeamCell v-bind="item.teams[0]" variant="row" />
        <AgentComposition
          :agents="item.teams[0].composition || []"
          :highlight="highlightAgentsTeam1"
          :variant="item.teams[0].team_side"
          size="sm"
        />
      </div>
    </template>

    <template #cell(score)="{ item }">
      <div v-if="item.replay_cropper_status !== 'executing'" class="score">
        <span class="score__result" :class="`score__result--${item.teams[0].win ? 'win' : 'loss'}`">{{
          item.teams[0].rounds_won
        }}</span>
        <span class="score__separator">:</span>
        <span class="score__result" :class="`score__result--${item.teams[1].win ? 'win' : 'loss'}`">{{
          item.teams[1].rounds_won
        }}</span>
      </div>
      <VodStatusIndicator v-else :status="item.replay_cropper_status" />
    </template>

    <template #cell(team2)="{ item }">
      <div class="column">
        <TeamCell v-bind="item.teams[1]" variant="row" />
        <AgentComposition
          :agents="item.teams[1].composition || []"
          :highlight="highlightAgentsTeam2"
          :variant="item.teams[1].team_side"
          size="sm"
        />
      </div>
    </template>

    <template #cell(rounds_played)="{ item }">
      <template v-if="item.replay_cropper_status !== 'executing'">
        {{ item.rounds_played }}
      </template>
      <VodStatusIndicator v-else :status="item.replay_cropper_status" />
    </template>

    <template #cell(diff)="{ item }">
      <template v-if="item.replay_cropper_status !== 'executing'">
        {{ Math.abs(item.teams[0].rounds_won - item.teams[1].rounds_won) }}
      </template>
      <VodStatusIndicator v-else :status="item.replay_cropper_status" />
    </template>

    <template #cell(actions)="{ item }">
      <template v-if="item.replay_cropper_status && item.replay_cropper_status !== 'failed'">
        <div class="column">
          <router-link class="btn btn-outline-primary action action--stats" :to="`/scrim/${item.id}`">
            Stats
          </router-link>
          <router-link
            class="btn btn-outline-primary action action--maps"
            :to="`/scrim/${item.id}/map`"
            @click.native="trackMapClick($event, item)"
          >
            2D Map
          </router-link>
        </div>
      </template>
      <VodStatusIndicator v-else :status="item.replay_cropper_status" />
    </template>

    <template v-for="(_, slot) of $scopedSlots" #[slot]="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
  </AppTable>
</template>

<script>
import { BIconPencil } from 'bootstrap-vue'
import px from 'vue-types'
import { mapGetters } from 'vuex'

import isoString from '../../filters/isoString.js'
import localDate from '../../filters/localDate.js'
import localTime from '../../filters/localTime.js'
import mixpanel from '../../mixpanel.js'
import AppTable from '../generic/Table.vue'
import MapCell from '../Table/cells/MapCell.vue'
import TeamCell from '../Table/cells/TeamCell.vue'
import AgentComposition from '../UI/AgentComposition.vue'
import VodStatusIndicator from '../UI/VodStatusIndicator.vue'

export default {
  name: 'ScrimTable',
  components: { AgentComposition, VodStatusIndicator, TeamCell, MapCell, AppTable, BIconPencil },
  props: {
    highlightAgentsTeam1: px.oneOfType([px.objectOf(px.bool), px.arrayOf(px.string)]),
    highlightAgentsTeam2: px.oneOfType([px.objectOf(px.bool), px.arrayOf(px.string)]),
    items: px
      .oneOfType([
        px.func,
        px.arrayOf(
          px.shape({
            id: px.string.isRequired,
          }).loose
        ),
      ])
      .isRequired.def([]),
    selected: px.arrayOf(px.shape({ id: px.string.isRequired }).loose),
    trackingContext: px.object.def({}),
  },
  computed: {
    ...mapGetters({
      getMapById: 'static/getMapById',
    }),

    computedItems() {
      return this.items.map(item => {
        const teamAAgentIds = item.teams[0].agent_ids
        const teamBAgentIds = item.teams[1].agent_ids
        return {
          ...item,
          teams: item.teams.map(team => {
            return {
              ...team,
              composition: team.agent_ids.map(id => {
                return {
                  id,
                  intersecting: teamAAgentIds.includes(id) && teamBAgentIds.includes(id),
                }
              }),
            }
          }),
        }
      })
    },

    fields() {
      return [
        {
          key: 'interact',
          label: '',
        },
        { key: 'name', label: 'Title' },
        {
          key: 'created',
          label: 'Date',
          formatter: localDate,
          sortable: true,
        },
        { key: 'map', label: 'Map' },
        {
          key: 'team1',
          label: 'Team 1',
        },
        { key: 'score', label: 'vs', tdClass: 'cell--score' },
        {
          key: 'team2',
          label: 'Team 2',
        },
        {
          key: 'rounds_played',
          label: 'Rounds',
          sortable: true,
        },
        {
          key: 'diff',
          label: 'Diff',
          sortable: true,
        },
        {
          key: 'actions',
          label: '',
        },
      ]
    },

    selectedIds() {
      return Object.freeze(
        this.selected ? Object.fromEntries(this.selected.map((item, index) => [item.id, index])) : {}
      )
    },
  },
  filters: {
    localDate,
    localTime,
    isoString,
  },
  methods: {
    getTrackingContext(item) {
      return {
        ...this.trackingContext,
        scrimId: item.id,
      }
    },
    isSelected(item) {
      return item && this.selectedIds[item.id] >= 0
    },
    toggleSelected(item) {
      if (this.isSelected(item)) {
        const idx = this.selectedIds[item.id]
        const newSelected = [].concat(this.selected.slice(0, idx), this.selected.slice(idx + 1))
        this.$emit('update:selected', newSelected)
        mixpanel.track_deselect({ type: 'scrim', id: item.id, selected: newSelected }, this.getTrackingContext(item))
      } else {
        const newSelected = [].concat(this.selected, [item])
        this.$emit('update:selected', newSelected)
        mixpanel.track_select({ type: 'scrim', id: item.id, selected: newSelected }, this.getTrackingContext(item))
      }
    },
    trackStatsClick($event, item, ctx = this.getTrackingContext(item)) {
      mixpanel.track_link({ type: 'scrim', href: $event.target.href }, ctx)
    },
    trackMapClick($event, item, ctx = this.getTrackingContext(item)) {
      mixpanel.track_link({ type: 'maptool', href: $event.target.href }, ctx)
    },
  },
}
</script>

<style lang="scss" scoped>
.column {
  display: flex;
  flex-flow: column nowrap;

  &--right {
    align-items: flex-end;
  }
}

.score {
  height: 100%;
  display: block;
  text-align: center;
  white-space: nowrap;

  &__result {
    font-weight: 700;
    font-size: 30px;
    line-height: 1;
    display: inline-block;
    width: 1.5em;

    &:first-of-type {
      text-align: right;
    }

    &:last-of-type {
      text-align: left;
    }

    &--win {
      color: $highlight;
    }
  }

  &__separator {
    font-weight: 400;
    font-size: 30px;
    line-height: 0.8;
    min-width: 0.75em;
    display: inline-block;
  }
}

.cell {
  &--score {
    text-align: center;
  }
}

.action {
  color: $white;
  white-space: nowrap;
}
</style>
