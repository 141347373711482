<template>
  <ApiListController :fetch="fetch" :filter="fetchParams" name="Matches" :per-page.sync="perPageValue">
    <template #default="{ items }">
      <component
        :is="tableComponent"
        :items="items"
        :highlight-agents-team1="fetchParams.agent_ids"
        :highlight-agents-team2="fetchParams.agent_vs_ids"
        no-local-sorting
        :sort-by="sortBy"
        :sort-desc="sortDesc"
        @sort-changed="onSortChanged"
        v-bind="$attrs"
        v-on="$listeners"
      />
    </template>
  </ApiListController>
</template>

<script>
import px from 'vue-types'

import { findScrims } from '../../api/scrims.js'
import { DEFAULT_ITEMS_PER_PAGE } from '../../store/modules/search.js'
import ApiListController from '../controllers/ApiListController.vue'
import { pxNullable } from '../Map/types.js'

import ScrimTable from './ScrimTable.vue'

export default {
  name: 'ScrimSearchController',
  components: {
    ApiListController,
  },
  props: {
    filter: px.object,
    perPage: pxNullable(px.integer).def(null),
    table: pxNullable(px.object).def(null),
  },
  data: () => ({
    localPerPage: DEFAULT_ITEMS_PER_PAGE,
    sortBy: 'created_millis',
    sortDesc: true,
  }),
  computed: {
    apiSortBy() {
      switch (this.sortBy) {
        case 'created_millis':
          return 'created'
        case 'summary.round_count':
          return 'rounds_played'
        case 'diff':
          return 'rounds_won_diff'
        default:
          return this.sortBy
      }
    },
    fetchParams() {
      return Object.freeze({
        ...this.filter,
        sortBy: this.apiSortBy,
        sortDesc: this.sortDesc,
      })
    },
    perPageValue: {
      get() {
        return this.perPage != null ? this.perPage : this.localPerPage
      },
      set(value) {
        this.localPerPage = value
        this.$emit('update:per-page', value)
      },
    },
    tableComponent() {
      return this.table || ScrimTable
    },
  },
  methods: {
    async fetch(params, config) {
      const { data, total } = await findScrims(params, config)
      return { data, total }
    },
    onSortChanged(event) {
      this.sortBy = event.sortBy
      this.sortDesc = event.sortDesc
    },
  },
}
</script>

<style scoped></style>
