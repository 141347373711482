var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ApiListController", {
    attrs: {
      fetch: _vm.fetch,
      filter: _vm.fetchParams,
      name: "Matches",
      "per-page": _vm.perPageValue
    },
    on: {
      "update:perPage": function($event) {
        _vm.perPageValue = $event
      },
      "update:per-page": function($event) {
        _vm.perPageValue = $event
      }
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var items = ref.items
          return [
            _c(
              _vm.tableComponent,
              _vm._g(
                _vm._b(
                  {
                    tag: "component",
                    attrs: {
                      items: items,
                      "highlight-agents-team1": _vm.fetchParams.agent_ids,
                      "highlight-agents-team2": _vm.fetchParams.agent_vs_ids,
                      "no-local-sorting": "",
                      "sort-by": _vm.sortBy,
                      "sort-desc": _vm.sortDesc
                    },
                    on: { "sort-changed": _vm.onSortChanged }
                  },
                  "component",
                  _vm.$attrs,
                  false
                ),
                _vm.$listeners
              )
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }