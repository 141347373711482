<template>
  <div>
    <PageTitle>Scrims</PageTitle>

    <AppTabs lazy>
      <AppTab title="Search">
        <SearchStateController ref="searchCtrl" :default="defaultFilters" :s="s" @change="onSearchChange">
          <template #default="{ state }">
            <ScrimSearchFilters :value="state" @update="onSearch" />

            <hr />

            <AppTabs>
              <AppTab title="Found matches">
                <div class="mt-2"></div>
                <ScrimSearchController
                  :filter="state"
                  :per-page="perPage"
                  @update:per-page="updateItemsPerPage"
                  :selected.sync="selectedMatches"
                  :tracking-context="{ page: 'scrims', section: 'results' }"
                />
              </AppTab>

              <AppTab :title="`Selected matches (${selectedMatchesCount})`">
                <div class="mt-2"></div>
                <ScrimTable
                  :items="selectedMatches"
                  :selected.sync="selectedMatches"
                  :tracking-context="{ page: 'scrims', section: 'selected' }"
                />
              </AppTab>

              <MapTab
                :active.sync="mapTool.active"
                :matches="selectedMatches"
                :map.sync="mapTool.map"
                scrim
                :team.sync="mapTool.team"
                :tracking-context="{ page: 'scrims', section: 'map' }"
              />
            </AppTabs>
          </template>
        </SearchStateController>
      </AppTab>

      <template #tabs-end>
        <BNavItem class="border border-bottom-0 border-info ml-1" to="/scrims/new"> New Scrim </BNavItem>
        <BNavItem v-if="!hideBrokenStuff" class="border border-bottom-0 border-info ml-1" to="/scrims/stats">
          Stats
        </BNavItem>
      </template>
    </AppTabs>
  </div>
</template>

<script>
import { BNavItem } from 'bootstrap-vue'
import px from 'vue-types'
import { mapGetters, mapMutations } from 'vuex'

import SearchStateController from '@/components/controllers/SearchStateController.vue'
import PageTitle from '@/components/generic/PageTitle.vue'
import AppTab from '@/components/generic/Tab.vue'
import AppTabs from '@/components/generic/Tabs.vue'
import { pxNullable } from '@/components/Map/types'
import ScrimSearchFilters from '@/components/scrim/ScrimSearchFilters.vue'
import MapTab from '@/components/tabs/MapTab.vue'
import mixpanel from '@/mixpanel'
import { DEFAULT_ITEMS_PER_PAGE } from '@/store/modules/search'
import { serializeQuery } from '@/utils/searchQuery'

import ScrimSearchController from '../../components/scrim/ScrimSearchController.vue'
import ScrimTable from '../../components/scrim/ScrimTable.vue'

export default {
  name: 'Scrims',
  components: {
    MapTab,
    BNavItem,
    ScrimTable,
    ScrimSearchController,
    SearchStateController,
    AppTab,
    AppTabs,
    PageTitle,
    ScrimSearchFilters,
  },
  props: {
    s: pxNullable(px.string).def(null),
  },
  data() {
    return {
      error: null,
      loading: 0,
      mapTool: {
        active: false,
        map: null,
        team: null,
      },
      perPage: DEFAULT_ITEMS_PER_PAGE,
      selectedMatches: [],
    }
  },
  computed: {
    ...mapGetters({
      defaultFilters: 'search/scrimSearchFilters',
      defaultItemsPerPage: 'search/itemsPerPage',
    }),
    selectedMatchesCount() {
      return this.selectedMatches?.length
    },
  },
  mounted() {
    this.perPage = this.defaultItemsPerPage
  },
  methods: {
    ...mapMutations({
      resetFilters: 'search/RESET_FILTERS',
      saveFilters: 'search/SET_FILTERS',
      setItemsPerPage: 'search/SET_ITEMS_PER_PAGE',
    }),

    async onSearch(query) {
      this.loading++
      try {
        this.selectedMatches = []
        const serializedQuery = await serializeQuery(query)
        if (query == null) {
          this.resetFilters()
        } else {
          this.saveFilters(query)
        }
        if (this.s !== serializedQuery) {
          await this.$router.push({ query: { s: serializedQuery != null ? serializedQuery : undefined } })
        } else {
          await this.$refs.searchCtrl.retry()
        }
      } finally {
        this.loading--
      }

      mixpanel.track_search(query, query ? 'Search' : 'Reset')
    },
    onSearchChange() {
      this.selectedMatches = []
    },
    updateItemsPerPage(count) {
      this.perPage = count
      this.setItemsPerPage(count)
    },
  },
}
</script>

<style lang="scss" scoped></style>
