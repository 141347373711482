var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageTitle", [_vm._v("Scrims")]),
      _c(
        "AppTabs",
        {
          attrs: { lazy: "" },
          scopedSlots: _vm._u([
            {
              key: "tabs-end",
              fn: function() {
                return [
                  _c(
                    "BNavItem",
                    {
                      staticClass: "border border-bottom-0 border-info ml-1",
                      attrs: { to: "/scrims/new" }
                    },
                    [_vm._v(" New Scrim ")]
                  ),
                  !_vm.hideBrokenStuff
                    ? _c(
                        "BNavItem",
                        {
                          staticClass:
                            "border border-bottom-0 border-info ml-1",
                          attrs: { to: "/scrims/stats" }
                        },
                        [_vm._v(" Stats ")]
                      )
                    : _vm._e()
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c(
            "AppTab",
            { attrs: { title: "Search" } },
            [
              _c("SearchStateController", {
                ref: "searchCtrl",
                attrs: { default: _vm.defaultFilters, s: _vm.s },
                on: { change: _vm.onSearchChange },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var state = ref.state
                      return [
                        _c("ScrimSearchFilters", {
                          attrs: { value: state },
                          on: { update: _vm.onSearch }
                        }),
                        _c("hr"),
                        _c(
                          "AppTabs",
                          [
                            _c(
                              "AppTab",
                              { attrs: { title: "Found matches" } },
                              [
                                _c("div", { staticClass: "mt-2" }),
                                _c("ScrimSearchController", {
                                  attrs: {
                                    filter: state,
                                    "per-page": _vm.perPage,
                                    selected: _vm.selectedMatches,
                                    "tracking-context": {
                                      page: "scrims",
                                      section: "results"
                                    }
                                  },
                                  on: {
                                    "update:per-page": _vm.updateItemsPerPage,
                                    "update:selected": function($event) {
                                      _vm.selectedMatches = $event
                                    }
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "AppTab",
                              {
                                attrs: {
                                  title:
                                    "Selected matches (" +
                                    _vm.selectedMatchesCount +
                                    ")"
                                }
                              },
                              [
                                _c("div", { staticClass: "mt-2" }),
                                _c("ScrimTable", {
                                  attrs: {
                                    items: _vm.selectedMatches,
                                    selected: _vm.selectedMatches,
                                    "tracking-context": {
                                      page: "scrims",
                                      section: "selected"
                                    }
                                  },
                                  on: {
                                    "update:selected": function($event) {
                                      _vm.selectedMatches = $event
                                    }
                                  }
                                })
                              ],
                              1
                            ),
                            _c("MapTab", {
                              attrs: {
                                active: _vm.mapTool.active,
                                matches: _vm.selectedMatches,
                                map: _vm.mapTool.map,
                                scrim: "",
                                team: _vm.mapTool.team,
                                "tracking-context": {
                                  page: "scrims",
                                  section: "map"
                                }
                              },
                              on: {
                                "update:active": function($event) {
                                  return _vm.$set(_vm.mapTool, "active", $event)
                                },
                                "update:map": function($event) {
                                  return _vm.$set(_vm.mapTool, "map", $event)
                                },
                                "update:team": function($event) {
                                  return _vm.$set(_vm.mapTool, "team", $event)
                                }
                              }
                            })
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }