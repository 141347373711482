var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "AppTable",
    _vm._g(
      _vm._b(
        {
          ref: "table",
          attrs: { fields: _vm.fields, items: _vm.items, "primary-key": "id" },
          scopedSlots: _vm._u(
            [
              {
                key: "cell(interact)",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c("label", [
                      _c("input", {
                        attrs: { type: "checkbox", disabled: item.status },
                        domProps: { checked: _vm.isSelected(item) },
                        on: {
                          change: function($event) {
                            return _vm.toggleSelected(item)
                          }
                        }
                      })
                    ])
                  ]
                }
              },
              {
                key: "cell(name)",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c("div", [_vm._v(_vm._s(item.name))]),
                    _c(
                      "router-link",
                      {
                        staticClass:
                          "btn btn-outline-primary action action--stats",
                        attrs: { to: "/scrim/" + item.id + "/edit" }
                      },
                      [_c("b-icon-pencil"), _vm._v(" Edit ")],
                      1
                    )
                  ]
                }
              },
              {
                key: "cell(created)",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c(
                      "div",
                      { staticClass: "column" },
                      [
                        _c("VodStatusIndicator", {
                          attrs: { status: item.replay_cropper_status }
                        }),
                        _c(
                          "time",
                          {
                            staticClass: "column",
                            attrs: {
                              datetime: _vm._f("isoString")(item.created)
                            }
                          },
                          [
                            _c("span", [
                              _vm._v(_vm._s(_vm._f("localDate")(item.created)))
                            ]),
                            _c("span", [
                              _vm._v(_vm._s(_vm._f("localTime")(item.created)))
                            ])
                          ]
                        )
                      ],
                      1
                    )
                  ]
                }
              },
              {
                key: "cell(map)",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    item.map
                      ? _c("MapCell", {
                          attrs: { id: item.map, variant: "col", size: "lg" }
                        })
                      : _c("VodStatusIndicator", {
                          attrs: { status: item.replay_cropper_status }
                        })
                  ]
                }
              },
              {
                key: "cell(team1)",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c(
                      "div",
                      { staticClass: "column" },
                      [
                        _c(
                          "TeamCell",
                          _vm._b(
                            { attrs: { variant: "row" } },
                            "TeamCell",
                            item.teams[0],
                            false
                          )
                        ),
                        _c("AgentComposition", {
                          attrs: {
                            agents: item.teams[0].composition || [],
                            highlight: _vm.highlightAgentsTeam1,
                            variant: item.teams[0].team_side,
                            size: "sm"
                          }
                        })
                      ],
                      1
                    )
                  ]
                }
              },
              {
                key: "cell(score)",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    item.replay_cropper_status !== "executing"
                      ? _c("div", { staticClass: "score" }, [
                          _c(
                            "span",
                            {
                              staticClass: "score__result",
                              class:
                                "score__result--" +
                                (item.teams[0].win ? "win" : "loss")
                            },
                            [_vm._v(_vm._s(item.teams[0].rounds_won))]
                          ),
                          _c("span", { staticClass: "score__separator" }, [
                            _vm._v(":")
                          ]),
                          _c(
                            "span",
                            {
                              staticClass: "score__result",
                              class:
                                "score__result--" +
                                (item.teams[1].win ? "win" : "loss")
                            },
                            [_vm._v(_vm._s(item.teams[1].rounds_won))]
                          )
                        ])
                      : _c("VodStatusIndicator", {
                          attrs: { status: item.replay_cropper_status }
                        })
                  ]
                }
              },
              {
                key: "cell(team2)",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c(
                      "div",
                      { staticClass: "column" },
                      [
                        _c(
                          "TeamCell",
                          _vm._b(
                            { attrs: { variant: "row" } },
                            "TeamCell",
                            item.teams[1],
                            false
                          )
                        ),
                        _c("AgentComposition", {
                          attrs: {
                            agents: item.teams[1].composition || [],
                            highlight: _vm.highlightAgentsTeam2,
                            variant: item.teams[1].team_side,
                            size: "sm"
                          }
                        })
                      ],
                      1
                    )
                  ]
                }
              },
              {
                key: "cell(rounds_played)",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    item.replay_cropper_status !== "executing"
                      ? [_vm._v(" " + _vm._s(item.rounds_played) + " ")]
                      : _c("VodStatusIndicator", {
                          attrs: { status: item.replay_cropper_status }
                        })
                  ]
                }
              },
              {
                key: "cell(diff)",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    item.replay_cropper_status !== "executing"
                      ? [
                          _vm._v(
                            " " +
                              _vm._s(
                                Math.abs(
                                  item.teams[0].rounds_won -
                                    item.teams[1].rounds_won
                                )
                              ) +
                              " "
                          )
                        ]
                      : _c("VodStatusIndicator", {
                          attrs: { status: item.replay_cropper_status }
                        })
                  ]
                }
              },
              {
                key: "cell(actions)",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    item.replay_cropper_status &&
                    item.replay_cropper_status !== "failed"
                      ? [
                          _c(
                            "div",
                            { staticClass: "column" },
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass:
                                    "btn btn-outline-primary action action--stats",
                                  attrs: { to: "/scrim/" + item.id }
                                },
                                [_vm._v(" Stats ")]
                              ),
                              _c(
                                "router-link",
                                {
                                  staticClass:
                                    "btn btn-outline-primary action action--maps",
                                  attrs: { to: "/scrim/" + item.id + "/map" },
                                  nativeOn: {
                                    click: function($event) {
                                      return _vm.trackMapClick($event, item)
                                    }
                                  }
                                },
                                [_vm._v(" 2D Map ")]
                              )
                            ],
                            1
                          )
                        ]
                      : _c("VodStatusIndicator", {
                          attrs: { status: item.replay_cropper_status }
                        })
                  ]
                }
              },
              _vm._l(_vm.$scopedSlots, function(_, slot) {
                return {
                  key: slot,
                  fn: function(scope) {
                    return [_vm._t(slot, null, null, scope)]
                  }
                }
              })
            ],
            null,
            true
          )
        },
        "AppTable",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }